@import "~normalize-scss/sass/normalize";
@import url("https://fonts.googleapis.com/css2?family=Roboto:wght@400;700&display=swap");

@include normalize();

*,
*::before,
*::after {
  box-sizing: border-box;
}

html,
body {
  margin: 0;
  padding: 0;
  font-size: 16px;
  background-color: #f5f5f7;
  font-family: "Roboto", sans-serif;
}
