// media query for mobile
@mixin mobile {
  @media screen and (orientation: portrait) and (max-width: 5in),
    screen and (orientation: landscape) and (max-width: 8in) {
    @content;
  }
}

// media query for tablets
@mixin tablet {
  @media screen and (max-width: 1125px) {
    @content;
  }
}
