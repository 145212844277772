.button {
  border: none;
  cursor: pointer;
  border-radius: 4px;
  background-color: rgba($black, 0.25);
  padding: 0.75rem 1rem;
  font-size: 1.2rem;
  color: $text-color;
  text-align: center;
  box-shadow: 0px 3px 1px -2px rgba($black, 0.2),
    0px 2px 2px 0px rgba($black, 0.14), 0px 1px 5px 0px rgba($black, 0.14);

  &.link {
    text-decoration: none;
    background-color: transparent;
    box-shadow: none;

    &:hover {
      text-decoration: underline;
    }
  }

  &:disabled {
    opacity: 0.7;
    cursor: default;
  }
}
