$size: 20px;
$anim-duration: 1.2s;

@keyframes dot-anim {
  from {
    opacity: 0;
    transform: scale(0);
  }

  50% {
    opacity: 1;
    transform: scale(1);
  }

  to {
    opacity: 0;
    transform: scale(0);
  }
}

.loader {
  &__dot {
    opacity: 0;
    margin: 4px;
    width: $size;
    height: $size;
    border-radius: 50%;
    display: inline-block;
    animation: dot-anim $anim-duration ($anim-duration / 6) ease infinite;

    &--black {
      background-color: #000;
    }

    &--yellow {
      background-color: $primary;
    }

    &:first-child {
      animation: dot-anim $anim-duration ease infinite;
    }

    &:last-child {
      animation: dot-anim $anim-duration ($anim-duration * 2 / 6) ease infinite;
    }
  }

  &--small &__dot {
    width: $size / 2;
    height: $size / 2;
  }
}
