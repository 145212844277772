$transition: 200ms cubic-bezier(0, 0, 0.2, 1);

.input {
  position: relative;
  width: 100%;
  background-color: $white;

  &--multiline {
    height: 120px;
  }

  &::before {
    left: 0;
    right: 0;
    bottom: 0;
    content: "\00a0";
    position: absolute;
    transition: border-bottom-color 200ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;
    border-bottom: 2px solid #ddd;
    pointer-events: none;
  }

  &::after {
    left: 0;
    right: 0;
    bottom: 0;
    content: "";
    position: absolute;
    transform: scaleX(0);
    transition: transform $transition;
    border-bottom: 2px solid $blue;
    pointer-events: none;
  }

  &--focused {
    background-color: rgba(0, 0, 0, 0.05);
  }

  &--focused::after {
    transform: scaleX(1);
  }

  &__field {
    width: 100%;
    height: 100%;
    border: none;
    resize: none;
    outline: none;
    font-size: 1rem;
    padding: 1.25rem 1rem;
    background-color: transparent;
  }
}
